import styles from './DynamicCard.module.css';
import { ReactNode } from 'react';
import { Button, Loader, Skeleton, Text } from '@mantine/core';

type DynamicCardProps = {
	title?: string;
	content: ReactNode | ReactNode[] | string;
	hint?: ReactNode | ReactNode[] | string;
	button?: ButtonProps;
	hover?: boolean;
	big?: boolean;
	color?: string;
	size?: 'sm' | 'md' | 'lg';
	orientation?: 'horizontal' | 'vertical';
	width?: string;
	float?: 'left' | 'right';
	className?: string;
	onClick?: () => void;
	icon?: ReactNode;
	[key: string]: any;
};

type ButtonProps = {
	color: string;
	text: string;
	onClick?: () => void;
};

export default function DynamicCard({
	title,
	content,
	button,
	hover,
	big,
	color,
	orientation,
	width,
	float,
	size,
	hint,
	onClick,
	className,
	icon,
	...props
}: DynamicCardProps) {
	const style = {
		...(color && color.length === 7
			? {
					backgroundColor: `${color}44`
			  }
			: {}),
		maxWidth: width || '100%',
		minWidth: '250px'
	};

	let contentStyle = {};
	if (size === 'sm') contentStyle = { fontSize: '1rem' };
	if (size === 'md') contentStyle = { fontSize: '1.2rem' };
	if (size === 'lg') contentStyle = { fontSize: '1.4rem' };

	return (
		<div
			{...props}
			onClick={() => {
				onClick?.();
			}}
			style={style}
			className={styles.card + ' ' + className}
			data-orientation={orientation || 'horizontal'}
			data-big={!!big}
			data-hover={!!hover}>
			{icon && <div className={styles.icon}>{icon}</div>}
			{title && <h3 className={styles.title}>{title}</h3>}
			<div style={contentStyle} className={styles.content} data-float={float}>
				{content == null ? <Loader color='white' variant='dots' /> : content}
			</div>
			{hint && (
				<Text color='dimmed' className={styles.hint} data-float={float}>
					{hint}
				</Text>
			)}
			{button && (
				<div className={styles.button}>
					<Button
						onClick={() => {
							button.onClick?.();
						}}
						color={button.color}>
						{button.text}
					</Button>
				</div>
			)}
		</div>
	);
}
