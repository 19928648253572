import { ReactNode } from 'react';
import styles from './ResponsiveLayout.module.css';

type ResponsiveLayoutProps = {
	children: ReactNode | ReactNode[];
	stretch?: boolean;
	centered?: boolean;
	width?: string;
	gap?: string | number;
};

export default function ResponsiveLayout({
	children,
	stretch,
	centered,
	width,
	gap
}: ResponsiveLayoutProps) {
	const style = {
		gap: gap || '0',
		maxWidth: width || '100%',
		marginLeft: centered ? 'auto' : '0',
		marginRight: centered ? 'auto' : '0'
	};

	return (
		<div
			style={style}
			data-stretch={stretch}
			className={styles.layout + (!!centered ? ' ' + styles.centered : '')}>
			{children}
		</div>
	);
}
