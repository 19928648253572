import { Dispatch, SetStateAction } from 'react';
import { DiscordData } from '../types/User';
import { InviteResponse } from '../types/api/InviteResponse';
import { ServerResponse } from '../types/api/ServerResponse';
import { Server } from '../types/Server';
import BaseResponse from '../types/api/BaseResponse';
import { toast } from 'react-toastify';
import { PluginDescriptionResponse, PluginQueryResponse } from '../types/api/PluginResponse';
import { TokenResponse } from '../types/api/TokenResponse';
import { InviteCreationData } from '../modals/CreateInviteModal';

const API = process.env.NEXT_PUBLIC_API_URL;
type versions = 'v1';

export default function useAPI() {
	const fetch = async (version: versions, path: string, options: RequestInit = {}) => {
		const controller = new AbortController();
		try {
			const res = await window.fetch(`${API}/${version}/${path}`, {
				...options,
				signal: controller.signal,
				headers: {
					'Content-Type': 'application/json',
					...options.headers
				}
			});

			setTimeout(() => controller.abort(), 5000);
			return res.json();
		} catch (_) {
			toast.error(`API Error: Request timed out.`);
			return { error: true, message: 'Request timed out' };
		}
	};

	const withToken = (token: string) => ({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});

	return {
		login: () => {
			window.location.href = `${API}/v1/discord/auth`;
		},
		logout: () => {
			document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		},
		getLoginURL: () => {
			return `${API}/v1/discord/auth`;
		},
		isLoggedIn: () => {
			const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
			const token = cookies.find((cookie) => cookie.startsWith('token='));
			return token ? token.split('=')[1] : null;
		},
		getStats: (callback?: Dispatch<SetStateAction<any>>) => {
			const data = fetch('v1', 'stats');
			if (callback) data.then(callback);
			return data;
		},
		getUserData: (token: string, callback?: Dispatch<SetStateAction<any>>) => {
			const data = fetch('v1', 'discord/data', withToken(token));
			if (callback) data.then(callback);
			return data as Promise<DiscordData>;
		},
		getUserDataOrScram: (token: string | null, callback?: Dispatch<SetStateAction<any>>) => {
			if (token == null) return (window.location.href = '/flow');
			fetch('v1', 'discord/data', withToken(token)).then((data) => {
				if (data.error) return (window.location.href = '/logout');
				if (!data.invite) return (window.location.href = '/flow');
				if (callback) callback(data);
			});
		},
		useInvite: (token: string, invite: string) => {
			return fetch('v1', `invite/confirm/${invite}`, withToken(token)) as Promise<InviteResponse>;
		},
		getServers: (token: string, callback?: Dispatch<SetStateAction<any>>) => {
			const data = fetch('v1', 'server', withToken(token));
			if (callback)
				data.then((d) => {
					if (d.error || !d.servers) return callback([]);
					callback(d.servers as Server[]);
				});
			return data;
		},
		getServer: (token: string, id: string, callback?: Dispatch<SetStateAction<any>>) => {
			const data = fetch('v1', `server/${id}`, withToken(token));
			if (callback) data.then(callback);
			return data;
		},
		getServerOrScram: (token: string, id: string, callback?: Dispatch<SetStateAction<any>>) => {
			if (token == null) return (window.location.href = '/flow');
			const hash = id.split('-')[0];
			fetch('v1', `server/${hash}?byHash`, withToken(token)).then((data: ServerResponse) => {
				if (data.error || !data.server) return (window.location.href = '/panel');
				if (callback) callback(data);
			});
		},
		createServer: (token: string, name: string) => {
			return fetch('v1', `server/${name}`, {
				...withToken(token),
				method: 'POST'
			}) as Promise<ServerResponse>;
		},
		deleteServer: (token: string, id: string) => {
			return fetch('v1', `server/${id}`, {
				...withToken(token),
				method: 'DELETE'
			}) as Promise<BaseResponse>;
		},
		server: {
			getSubusers: (token: string, id: string, callback?: Dispatch<SetStateAction<any>>) => {
				const data = fetch('v1', `server/${id}/subusers`, withToken(token));
				if (callback) data.then(callback);
				return data;
			},
			removeSubuser: (token: string, id: string, subuser: string) => {
				return fetch('v1', `server/${id}/subusers`, {
					...withToken(token),
					body: JSON.stringify({ user: subuser }),
					method: 'DELETE'
				}) as Promise<BaseResponse>;
			},
			addSubuser: (token: string, id: string, subuser: string, permissions?: bigint) => {
				return fetch('v1', `server/${id}/subusers`, {
					...withToken(token),
					body: JSON.stringify({ user: subuser, permissions }),
					method: 'POST'
				}) as Promise<BaseResponse>;
			},
			resetToken: (token: string, id: string) => {
				return fetch('v1', `server/${id}/reset_token`, {
					...withToken(token),
					method: 'POST'
				}) as Promise<TokenResponse>;
			},
			ftp: {
				get: (token: string, id: string, callback?: Dispatch<SetStateAction<any>>) => {
					const data = fetch('v1', `server/${id}/ftp`, withToken(token));
					if (callback) data.then(callback);
					return data;
				},
				reset: (token: string, id: string) => {
					return fetch('v1', `server/${id}/ftp/reset`, {
						...withToken(token),
						method: 'POST'
					}) as Promise<TokenResponse>;
				}
			}
		},
		files: {
			downloadFile: async (token: string, id: string, path: string) => {
				if (path.startsWith('/')) path = path.slice(1);
				return await window.fetch(`${API}/v1/server/${id}/file/download/${path}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
			}
		},
		plugins: {
			search: (query: string, limit?: number, page?: number, sort?: string) => {
				let params = '?';
				if (sort) params += 'sort=' + sort + '&';
				if (limit) params += 'limit=' + limit + '&';
				if (page) params += 'page=' + page + '&';

				return fetch(
					'v1',
					`plugin/search/${query}${params.substring(0, params.length - 1)}`
				) as Promise<PluginQueryResponse>;
			},
			description: (id: string) => {
				return fetch('v1', `plugin/description/${id}`) as Promise<PluginDescriptionResponse>;
			}
		},
		admin: {
			createInvite: (
				token: string,
				data: InviteCreationData,
				callback?: Dispatch<SetStateAction<any>>
			) => {
				const invite = fetch('v1', `admin/invite?uses=${data.uses}&admin=${data.admin}`, {
					...withToken(token),
					method: 'POST'
				});
				if (callback) invite.then(callback);
				return invite;
			},
			getAllStats: (token: string, callback?: Dispatch<SetStateAction<any>>) => {
				const data = fetch('v1', 'admin/stats', withToken(token));
				if (callback) data.then(callback);
				return data;
			},
			getUsers: (token: string, callback?: Dispatch<SetStateAction<any>>) => {
				const data = fetch('v1', 'admin/users', withToken(token));
				if (callback) data.then(callback);
				return data;
			},
			getInvites: (token: string, callback?: Dispatch<SetStateAction<any>>) => {
				const data = fetch('v1', 'admin/invites', withToken(token));
				if (callback) data.then(callback);
				return data;
			},
			getServers: (token: string, callback?: Dispatch<SetStateAction<any>>) => {
				const data = fetch('v1', 'admin/servers', withToken(token));
				if (callback) data.then(callback);
				return data;
			}
		}
	};
}
