import { Badge, Button, Space, Text } from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { AiFillCloud, AiFillFile } from 'react-icons/ai';
import {
	BiRightArrow,
	BiRightArrowAlt,
	BiRightArrowCircle,
	BiServer,
	BiTerminal,
	BiUser,
	BiUserCircle
} from 'react-icons/bi';
import { BsFillPuzzleFill, BsFillTerminalFill, BsPuzzle, BsTag } from 'react-icons/bs';
import { FaPuzzlePiece, FaUserAlt, FaUserTag } from 'react-icons/fa';
import { FiArrowRight, FiFile } from 'react-icons/fi';
import {
	IoExtensionPuzzle,
	IoExtensionPuzzleOutline,
	IoExtensionPuzzleSharp
} from 'react-icons/io5';
import DynamicCard from '../components/dynamic/card/DynamicCard';
import PageWave from '../components/svg/PageWave/PageWave';
import useAPI from '../hooks/useAPI';
import ResponsiveLayout from '../layouts/ResponsiveLayout';
import styles from '../styles/Index.module.css';

export default function Home() {
	const [stats, setStats] = useState<any | null>(null);
	const api = useAPI();

	useEffect(() => {
		api.getStats(setStats);
	}, []);

	return (
		<>
			<Script src='/animator.js' defer />
			<div className={styles.container}>
				<Image
					className={styles.icon}
					src='/glass.png'
					alt='Glass Logo'
					width={96}
					height={96}
					priority
				/>
				<h1 className={`${styles.title} title`}>Glass</h1>

				<Space h='10%' />

				<div className={styles.buttons}>
					<Link href='/panel'>
						<Button variant='outline' color='green'>
							Login
						</Button>
					</Link>
					<Button
						variant='outline'
						color='blue'
						onClick={(e) => {
							e.preventDefault();
							document.querySelector('#about')?.scrollIntoView({ behavior: 'smooth' });
						}}>
						Learn More
					</Button>
				</div>
			</div>

			<PageWave />

			<div className={styles.section}>
				<section id='about'>
					<Text size='xl' className={styles.heading} data-observe>
						Features
					</Text>
					<ResponsiveLayout centered width='50%'>
						<DynamicCard
							className={styles.card}
							data-observe
							width='20%'
							icon={<AiFillCloud size={32} color='#ff8888' />}
							content=<>
								<b>FTP Access</b>
								<br />
								Glass allows you to access your server files with FTP while your server is running.
							</>
						/>
						<DynamicCard
							className={styles.card}
							data-observe
							width='20%'
							icon={<AiFillFile size={32} color='#ffa574' />}
							content=<>
								<b>File Manager</b>
								<br />
								Easily upload, read, modify and download files all from the dashboard.
							</>
						/>
						<DynamicCard
							className={styles.card}
							data-observe
							width='20%'
							icon={<BsFillTerminalFill size={32} color='#ffdf74' />}
							content=<>
								<b>Console</b>
								<br />
								Run and view server commands and chat without having to join your server.
							</>
						/>
						<DynamicCard
							className={styles.card}
							data-observe
							width='20%'
							icon={<IoExtensionPuzzleSharp size={32} color='#7bff74' />}
							content=<>
								<b>Plugins</b> <Badge color='yellow'>Coming Soon</Badge>
								<br />
								Install, update and manage plugins directly from the dashboard.
							</>
						/>
						<DynamicCard
							className={styles.card}
							data-observe
							width='20%'
							icon={<FaUserAlt size={32} color='#74d1ff' />}
							content=<>
								<b>Player Manager</b>
								<br />
								View and manage players from the dashboard, including kicking and banning.
							</>
						/>
						<DynamicCard
							className={styles.card}
							data-observe
							width='20%'
							icon={<FaUserTag size={32} color='#ff74dc' />}
							content=<>
								<b>Subusers</b>
								<br />
								Give users access to manage your server with specific permissions.
							</>
						/>
					</ResponsiveLayout>
					<Space h='10em' />
					<Text size='xl' className={styles.heading} data-observe>
						Statistics
					</Text>
					<ResponsiveLayout centered width='50%'>
						<DynamicCard
							className={styles.card}
							data-observe
							orientation='horizontal'
							icon={<BiUser size={32} color='#ff8888' />}
							float='right'
							size='lg'
							content={stats?.users}
							hint='Total Users'
						/>
						<DynamicCard
							className={styles.card}
							data-observe
							orientation='horizontal'
							icon={<BiServer size={32} color='#7bff74' />}
							float='right'
							size='lg'
							content={stats?.servers}
							hint='Total Servers'
						/>
					</ResponsiveLayout>

					<Space h='10em' />
					<Text size='xl' className={styles.heading} data-observe>
						Ready to get started?
					</Text>
					<ResponsiveLayout centered width='50%'>
						<Link href='/flow' className={styles.animate} data-observe>
							<Button variant='filled' color='blue' rightIcon={<FiArrowRight size={16} />}>
								Get Started
							</Button>
						</Link>
					</ResponsiveLayout>
				</section>
			</div>
		</>
	);
}
