import styles from './PageWave.module.css';

export default function PageWave() {
	return (
		<>
			<svg
				className={styles.wave}
				style={{ transition: '0.3s' }}
				viewBox='0 0 1440 200'
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					style={{ transform: 'translate(-10px, 0px)', opacity: 1 }}
					className={styles.fill}
					d='M0,140L60,120C120,100,240,60,360,46.7C480,33,600,47,720,70C840,93,960,127,1080,126.7C1200,127,1320,93,1440,93.3C1560,93,1680,127,1800,143.3C1920,160,2040,160,2160,156.7C2280,153,2400,147,2520,150C2640,153,2760,167,2880,170C3000,173,3120,167,3240,150C3360,133,3480,107,3600,100C3720,93,3840,107,3960,93.3C4080,80,4200,40,4320,36.7C4440,33,4560,67,4680,83.3C4800,100,4920,100,5040,103.3C5160,107,5280,113,5400,116.7C5520,120,5640,120,5760,123.3C5880,127,6000,133,6120,123.3C6240,113,6360,87,6480,93.3C6600,100,6720,140,6840,133.3C6960,127,7080,73,7200,53.3C7320,33,7440,47,7560,56.7C7680,67,7800,73,7920,73.3C8040,73,8160,67,8280,56.7C8400,47,8520,33,8580,26.7L8640,20L8640,200L8580,200C8520,200,8400,200,8280,200C8160,200,8040,200,7920,200C7800,200,7680,200,7560,200C7440,200,7320,200,7200,200C7080,200,6960,200,6840,200C6720,200,6600,200,6480,200C6360,200,6240,200,6120,200C6000,200,5880,200,5760,200C5640,200,5520,200,5400,200C5280,200,5160,200,5040,200C4920,200,4800,200,4680,200C4560,200,4440,200,4320,200C4200,200,4080,200,3960,200C3840,200,3720,200,3600,200C3480,200,3360,200,3240,200C3120,200,3000,200,2880,200C2760,200,2640,200,2520,200C2400,200,2280,200,2160,200C2040,200,1920,200,1800,200C1680,200,1560,200,1440,200C1320,200,1200,200,1080,200C960,200,840,200,720,200C600,200,480,200,360,200C240,200,120,200,60,200L0,200Z'></path>
			</svg>
		</>
	);
}
